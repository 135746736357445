import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

const TermsOfUsePage = () => {
  const navigate = useNavigate();

  return (
    <MDBContainer className="mt-5">
      <MDBRow>
        <MDBCol>
          <h3>Terms of Use</h3>
          <p>Effective Date: 1 October, 2024</p>
          <h4>Introduction</h4>
          <p>
            These Terms of Use ("Terms") govern your access to and use of
            Orbtronics Ltd.’s ("Orbtronics," "we," "us," or "our") payment
            dashboard ("Service"). By using the Service, you agree to be bound
            by these Terms, including the End-User License Agreement (EULA) and
            the Privacy Policy.
          </p>

          <h4>1. End-User License Agreement</h4>
          <h4>License Grant</h4>
          <p>
            Orbtronics grants you a limited, non-exclusive, non-transferable,
            and revocable license to access and use the Service solely for the
            purpose of managing invoices and processing payments in accordance
            with these Terms.
          </p>

          <h4>Restrictions</h4>
          <p>
            You agree not to:
            <ul>
              <li>Use the Service for any unlawful or prohibited purpose.</li>
              <li>
                Modify, reverse engineer, or attempt to extract the source code
                of the Service.
              </li>
              <li>Transfer or sublicense your license to any third party.</li>
            </ul>
          </p>

          <h4>Account and Security</h4>
          <p>
            You are responsible for safeguarding your account credentials and
            any activities under your account.
          </p>

          <h4>Payment and Fees</h4>
          <p>
            The Service may include transaction fees as outlined in the separate
            pricing agreement between you and Orbtronics.
          </p>

          <h4>Termination</h4>
          <p>
            We may suspend or terminate your access to the Service for any
            breach of these Terms.
          </p>

          <h4>2. Privacy Policy</h4>
          <h4>Information We Collect</h4>
          <p>
            We collect personal information, such as your name, contact details,
            and payment data, to provide and improve our Service.
          </p>

          <h4>How We Use Your Information</h4>
          <p>
            We use your personal information to process payments, manage
            invoicing, and ensure compliance with legal obligations.
          </p>

          <h4>Sharing Your Information</h4>
          <p>
            Your personal information may be shared with third-party payment
            processors and legal authorities as required.
          </p>

          <h4>Data Security</h4>
          <p>
            We use industry-standard methods to secure your personal
            information, but we cannot guarantee complete security.
          </p>

          <h4>Retention of Information</h4>
          <p>
            We retain your information as long as necessary to fulfill the
            purposes outlined in this Privacy Policy.
          </p>

          <h4>Your Rights</h4>
          <p>
            You have the right to access, correct, or delete your personal
            information, and to opt-out of communications.
          </p>

          <h4>3. Disclaimer of Warranties</h4>
          <p>
            The Service is provided "AS IS" without any warranties. Orbtronics
            disclaims all warranties, either express or implied, regarding the
            Service’s availability, reliability, and performance.
          </p>

          <h4>4. Limitation of Liability</h4>
          <p>
            To the maximum extent permitted by law, Orbtronics shall not be
            liable for any indirect, incidental, special, or consequential
            damages arising from your use of the Service.
          </p>

          <h4>5. Governing Law</h4>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of Saint Lucia without regard to conflict of law
            principles.
          </p>

          <h4>6. Changes to These Terms</h4>
          <p>
            Orbtronics reserves the right to modify these Terms at any time.
            Your continued use of the Service after any changes indicates your
            acceptance of the modified Terms.
          </p>

          <h4>7. Contact Us</h4>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <p>
            Orbtronics Ltd
            <br />
            Grand Riviere, Saint Lucia
            <br />
            Email: info@orbtronicsstlucia.com
            <br />
            Phone: 1-758-486-0469
          </p>

          <MDBBtn color="primary" onClick={() => navigate("/client")}>
            Go to Home
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default TermsOfUsePage;
