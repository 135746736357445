import React, { useState, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./HomePage.css";
import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBCheckbox,
  MDBBtn,
} from "mdb-react-ui-kit";
import { loginUser } from "../../helpers/usersApi";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";

const HomePage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  // reCAPTCHA handler
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

   

    const user = await loginUser(email, password, recaptchaToken);
    if (user) {
      setUser(user);
      localStorage.setItem("orbtronics-user", JSON.stringify(user));
      if (user.is_admin) {
        navigate("/admin-dashboard");
      } else if (!user.is_admin) {
        navigate("/client");
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="container-fluid ps-md-0">
      <div className="row g-0">
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <h3 className="login-heading mb-4">Welcome back!</h3>
                  {/* Sign In Form */}
                  <form onSubmit={onSubmit}>
                    <MDBInput
                      data-test="login-email-input"
                      className="mb-4"
                      type="email"
                      id="form1Example1"
                      label="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <MDBInput
                      data-test="login-password-input"
                      className="mb-4"
                      type="password"
                      id="form1Example2"
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />

                    <MDBRow className="mb-4">
                      <MDBCol className="d-flex justify-content-center">
                        <MDBCheckbox
                          id="form1Example3"
                          label="Remember me"
                          defaultChecked
                        />
                      </MDBCol>
                      <MDBCol>
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </MDBCol>
                    </MDBRow>

                    

                    <MDBBtn type="submit" block>
                      {isloading ? <SmallLoadingSpinner /> : "Sign in"}
                    </MDBBtn>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
