import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";

const UpdateConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
  title,
  message,
}) => {
  const { isOpen, onOpenChange } = useDisclosure({
    isOpen: visible,
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        onCancel();
      }
    },
  });

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
            <ModalBody>
              <p>{message}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                data-test="cancel-button"
                color="danger"
                variant="light"
                onPress={() => {
                  onCancel();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                data-test="confirm-button"
                color="primary"
                onPress={() => {
                  onConfirm();
                  onClose();
                }}
              >
                Confirm
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UpdateConfirmationModal;
