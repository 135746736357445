import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const RenderLineChart = ({ analyticsData }) => {
  // console.log(analyticsData)
  const chartData = analyticsData.pageViews
    .filter((item) => [
      '/',
      '/shop/',
      '/contact-us/',
      '/product-category/corporate-casuals/',
      '/about-us/',
      '/customize/',
    ].includes(item.page))
    .map((item) => ({
      name: item.page === '/' ? 'Home' : item.page === '/product-category/corporate-casuals/' ? 'Products' : item.page === '/customize/' ? 'Customize' : item.page.slice(1, -1).replace('-', ' '),
      value: item.views,
    }));

  return (
    <BarChart width={600} height={400} data={chartData}>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      <Bar dataKey="value" fill="#3A71CA" />
    </BarChart>
  );
};

export default RenderLineChart;