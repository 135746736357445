import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Textarea,
} from "@nextui-org/react";

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../context/UserContext";

export default function ConfirmationModal({ invoice, setInvoice, setShowModal }) {
  const { user } = useContext(UserContext);
  const [adminName, setAdminName] = useState(
    user.first_name + " " + user.last_name
  );
  const [refundReason, setRefundReason] = useState("");
  const [refundDate, setRefundDate] = useState(new Date());
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateAdminName = (value) =>
    value.trim().length > 0 ? true : "Admin name is required";
  const validateRefundDate = (value) =>
    value !== "" ? true : "Refund date is required";
  const validateRefundReason = (value) =>
    value.trim().length > 0 ? true : "Reason for refund is required";
  useEffect(() => {
    const isValid =
      validateAdminName(adminName) === true &&
      validateRefundDate(refundDate.toISOString().split("T")[0]) === true &&
      validateRefundReason(refundReason) === true;

    setIsFormValid(isValid);
  }, [adminName, refundDate, refundReason]);
  console.log(invoice)


  async function handleRefund(e) {
    e.preventDefault();
    setIsSubmitting(true);

    if (!isFormValid) return;

    const formData = {
      adminID: user._id,
      user_id: invoice.user_id,
      adminUser: adminName,
      reason: refundReason,
      date: refundDate,
      refundAmount: invoice.amount,
      transactionId: invoice.transaction_id,
      paymentId: invoice._id
    };

    axios.post(`${process.env.REACT_APP_API_URL}/refund`, formData, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then(response => {
      if(response.status === 200){
         setShowModal(false); // Close the modal on success
         setInvoice((prevInvoice) => ({
           ...prevInvoice,
           status: "refunded",
         }));
         toast.success(
           response.data.message || "Refund processed successfully",
           { position: "top-center" }
         );
      }
    })
    .catch(error => {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.error("An error occurred while processing the refund. Please try again.", {position: "top-center"});
    })
    .finally(() => {
      setIsSubmitting(false);
    });  
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
      <form className="lg:ml-64 bg-white p-6 rounded shadow-md w-[95%] md:w-full max-w-xl xl:max-w-3xl my-8">
        <h2 className="text-lg font-medium mb-4">Confirm Refund</h2>
        <p className="mb-6">Are you sure you want to refund this payment?</p>

        <div className="mt-4">
          <Input
            className="nextui-input"
            label="Admin Name"
            value={adminName}
            onChange={(e) => setAdminName(e.target.value)}
            fullWidth
            isRequired
            isInvalid={validateAdminName(adminName) !== true}
            helperText={
              validateAdminName(adminName) !== true
                ? validateAdminName(adminName)
                : null
            }
          />
        </div>

        <div className="mt-4">
          <Input
            className="nextui-input"
            label="Refund Date"
            type="date"
            fullWidth
            value={refundDate.toISOString().split("T")[0]}
            onChange={(e) => setRefundDate(new Date(e.target.value))}
            isRequired
            isInvalid={
              validateRefundDate(refundDate.toISOString().split("T")[0]) !==
              true
            }
            helperText={
              validateRefundDate(refundDate.toISOString().split("T")[0]) !==
              true
                ? validateRefundDate(refundDate.toISOString().split("T")[0])
                : null
            }
          />
        </div>

        <div className="mt-4">
          <Textarea
            data-test="refund-textarea"
            label="Reason for Refund"
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
            fullWidth
            rows={3}
            isRequired
            isInvalid={validateRefundReason(refundReason) !== true}
            helperText={
              validateRefundReason(refundReason) !== true
                ? validateRefundReason(refundReason)
                : null
            }
          />
        </div>

        <div className="mt-4 flex-grow w-full">
          <Table aria-label="Invoice details">
            <TableHeader>
              <TableColumn>Title</TableColumn>
              <TableColumn>Quantity</TableColumn>
              <TableColumn>Description</TableColumn>
              <TableColumn>Status</TableColumn>
              <TableColumn>Total</TableColumn>
            </TableHeader>
            <TableBody>
              <TableRow key="1">
                <TableCell>{invoice.title}</TableCell>
                <TableCell>1</TableCell>
                <TableCell>{invoice.description}</TableCell>
                <TableCell>{invoice.status}</TableCell>
                <TableCell>${invoice.amount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div className="mt-2 text-right body-text">
            <p className="font-bold">TOTAL: ${invoice.amount}</p>
          </div>
        </div>

        <div className="flex justify-end gap-2 mt-6">
          <Button
            radius="sm"
            onClick={() => setShowModal(false)}
            className="bg-gray-400 text-gray-800 py-2 px-4 rounded"
          >
            Cancel
          </Button>
          <Button
            data-test="confirm-refund-button"
            radius="sm"
            onClick={handleRefund}
            className="bg-red-500 text-white py-2 px-4 rounded"
            isDisabled={!isFormValid || isSubmitting}
            isLoading={isSubmitting}
          >
            {isSubmitting ? "Processing..." : "Confirm"}
          </Button>
        </div>
      </form>
    </div>
  );
}