import {
  Button,
  DatePicker,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { findPayment } from "../../helpers/paymentApi";

import axios from "axios";
import { toast } from "react-toastify";
import { formatDateString } from "../../helpers/time";
import ErrorComponent from "../ErrorComponent";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import ConfirmationModal from "./ConfirmationModal";
import "./Invoice.css";
import UpdateConfirmationModal from "./UpdateConfirmationModal";

const Invoice = () => {
  const { id } = useParams();

  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState({});
  const [invoice, setInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);


  const [newDate, setNewDate] = useState("");
  const [showDueDateConfirmation, setShowDueDateConfirmation] = useState(false);
  const [showTotalConfirmation, setShowTotalConfirmation] = useState(false);



  const [editingTotal, setEditingTotal] = useState(false);
  const [newTotal, setNewTotal] = useState(invoice.amount);

  const navigate = useNavigate();

  const navigateToPayment = () => {
    if (invoice.status !== "complete") {
      navigate(`/CheckoutPage/${invoice._id}`);
    }
  };

  const dateFromObject = (dateObj) => {
    if (typeof dateObj === "object" && dateObj !== null) {
      const { year, month, day } = dateObj;
      const date = new Date(Date.UTC(year, month - 1, day));
      date.setUTCDate(date.getUTCDate() + 1);
      return date.toISOString();
    } else {
      return dateObj;
    }
  };

  const deleteInvoice = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/payments/${invoice._id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      toast.success("Payment deleted successfully.", { position: "top-center" });
      navigate("/all-invoices"); 
    } catch (error) {
      toast.error("Failed to delete the payment. " + error.message, {
        position: "top-center",
      });
    }
  };
  
  const updateInvoice = () => {
    setDisabledButton(true);
    const updateData = {};
    if (dateFromObject(newDate)) updateData.due_date = dateFromObject(newDate);
    if (newTotal) updateData.amount = parseInt(newTotal);

    try {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/payments/${invoice._id}`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message, { position: "top-center" });
          setInvoice((prevInvoice) => ({
            ...prevInvoice,
            ...(updateData.due_date && { due_date: updateData.due_date }),
            ...(updateData.amount && { amount: updateData.amount }),
            lastModified: new Date().toISOString(),
          }));
          setNewDate(invoice.due_date);
          setNewTotal(invoice.amount);
          setDisabledButton(false);
          setShowDueDateConfirmation(false);
          setShowTotalConfirmation(false);
          setEditDate(false);
          setEditingTotal(false);
        });
    } catch (error) {

      toast.error(error.message, {position: "top-center"});

      setDisabledButton(false);
    }
  };

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        setIsLoading(true);
        const invoiceObj = await findPayment(id, user);
        if (invoiceObj.message === "Payment found") {
          setInvoice(invoiceObj.data);
          setUserData(invoiceObj.userData);
        } else {
          setError(invoiceObj.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPayment();
  }, [id, user]);

  useEffect(() => {
    if (typeof newDate === "object" && newDate !== null) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [newDate]);

  useEffect(() => {
    setNewDate(invoice.due_date);
  }, [invoice.due_date]);

  if (isLoading) return <LargeLoadingSpinner />;
  if (error) return <ErrorComponent errorMessage={error} />;

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
      <div className="mb-6 columns-2 top-invoice d-flex justify-content-between align-items-center">
        <h1 className="text-base pt-2 font-semibold text-gray-800 inv-number">
          Invoice #{invoice._id}
        </h1>
        <div className="flex justify-end items-center gap-3 btns-box">
          <span
            className="font-bold orb-blue-text btn-text-size py-1 mr-3 px-2 print"
            onClick={() => window.print()}
          >
            PRINT
          </span>
          {invoice.status !== "complete" &&
            invoice.status !== "refunded" &&
            user.is_admin && (
              <Button
                data-test="edit-invoice-button"
                color="secondary"
                radius="sm"
                className={`${invoice.status}`}
                onClick={() => {
                  setEditDate((prevDate) => !prevDate);
                  setEditingTotal((prevTotal) => !prevTotal);
                }}
              >
                EDIT
              </Button>



            )}

{invoice.status !== "complete" &&
  invoice.status !== "refunded" &&
  user.is_admin && (
    <Button
      color="danger"
      radius="sm"
      onClick={() => {
        if (window.confirm("Are you sure you want to delete this payment?")) {
          deleteInvoice();
        }
      }}
    >
      DELETE
    </Button>
  )}


          {invoice.status === "complete" && user.is_admin && (
            <Button
              data-test="refund-invoice-button"
              color="primary"
              radius="sm"
              className={`${invoice.status}`}
              onClick={() => setShowModal(true)}
            >
              REFUND
            </Button>
          )}
          {showModal && (
            <ConfirmationModal
              invoice={invoice}
              setInvoice={setInvoice}
              setShowModal={setShowModal}
            />
          )}

          {invoice.status !== "complete" && invoice.status !== "refunded" && (
            <Button
              color="primary"
              radius="sm"
              className={`${invoice.status}`}
              isDisabled={invoice.status === "complete"}
              onClick={navigateToPayment}
            >
              PAY NOW
            </Button>
          )}
        </div>
      </div>
      <hr className="bg-black"></hr>
      <div className="flex mb-6 justify-between invoice-details-container d-flex justify-content-between">
        <div className="text-sm body-text">
          <p>From:</p>
          <p className="font-semibold">Orbtronics Ltd.</p>
          <p>Gros-Islet, Saint Lucia</p>

          <p>
            <span className="font-semibold">Date Paid: </span>
            {invoice.status === "complete"
              ? formatDateString(invoice.date_paid)
              : "Not Paid"}
          </p>
          <div>
            <span className="font-semibold">Due date: </span>{" "}
            {formatDateString(invoice.due_date)}
            {editDate && user.is_admin && (
              <div>
                <DatePicker
                  data-test="date-picker"
                  label="Due Date"
                  className="mt-3 max-w-[1/2]"
                  description="Update the due date"
                  onChange={setNewDate}
                />
                <Button
                  data-test="update-due-date-button"
                  isDisabled={disabledButton}
                  type="button"
                  onClick={() => setShowDueDateConfirmation(true)}
                  className="bg-[#3A71CA] text-white"
                >
                  Update
                </Button>
              </div>
            )}
          </div>
          <p>
            {formatDateString(invoice.lastModified) !== "Invalid Date" && (
              <>
                <span className="font-bold">Last Modified:</span>{" "}
                {formatDateString(invoice.lastModified)}
              </>
            )}{" "}
          </p>
        </div>
        <div className="text-sm body-text client-inv-details">
          <h1 className="text-base font-semibold text-gray-800 d-flex justify-content-end ">
            Invoice No.
          </h1>
          <p className="orb-blue-text font-semibold d-flex justify-content-end ">
            {invoice._id}
          </p>
          <p className="d-flex justify-content-end ">To:</p>
          <p className="font-semibold d-flex justify-content-end ">
            {userData.company}
          </p>
          <p className="d-flex justify-content-end ">
            {userData.companyAddress}
          </p>
        </div>
      </div>
      <div className="flex-grow w-full">
        <Table aria-label="Invoice details">
          <TableHeader>
            <TableColumn>Title</TableColumn>
            <TableColumn>Quantity</TableColumn>
            <TableColumn>Description</TableColumn>
            <TableColumn>Status</TableColumn>
            <TableColumn>Total</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow key="1">
              <TableCell>{invoice.title}</TableCell>
              <TableCell>1</TableCell>
              <TableCell>{invoice.description}</TableCell>
              <TableCell>{invoice.status}</TableCell>
              <TableCell>
                {editingTotal && user.is_admin ? (
                  <Input
                    data-test="edit-total-input"
                    type="number"
                    min="1"
                    value={newTotal || invoice.amount}
                    onChange={(e) => setNewTotal(e.target.value)}
                    onBlur={() => {
                      setShowTotalConfirmation(true);
                      setNewTotal((e) => (e !== "" ? e : invoice.amount));
                    }}
                  />
                ) : (
                  <span>${invoice.amount}</span>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className="mt-2 text-right body-text">
          <p data-test="invoice-total" className="font-bold">
            TOTAL: ${invoice.amount}
          </p>
        </div>
      </div>

      <UpdateConfirmationModal
        visible={showDueDateConfirmation}
        onConfirm={updateInvoice}
        onCancel={() => setShowDueDateConfirmation(false)}
        title="Confirm Due Date Update"
        message="Are you sure you want to update the due date?"
      />

      <UpdateConfirmationModal
        visible={showTotalConfirmation}
        onConfirm={updateInvoice}
        onCancel={() => setShowTotalConfirmation(false)}
        title="Confirm Total Update"
        message="Are you sure you want to update the total?"
      />
    </div>
  );
};

export default Invoice;
